@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;
@use 'styles/colors' as *;

.transportWrapper {
  @include flex;

  .btn,
  .input {
    max-width: 4rem;
    min-width: 2rem;
    max-height: 2rem;
    min-height: 1rem;
  }

  .btn {
    margin: 0 0.25rem;

    .icon {
      height: 1rem;
    }

    &#stop,
    &#start {
      background: var(--primary);
    }
    &#pause {
      background: var(--primary);
      &.flashing {
        animation: dim-flashing 300ms alternate infinite;
      }
    }
  }

  .inputWrapper {
    margin-left: 0.25rem;
    width: 7rem;
    @include flex;

    .input {
      @extend %input;
      padding: 0.25rem;
      overflow: hidden;
      text-align: center;
      font-size: 1.25rem;

      &.edited {
        animation: flashing 300ms alternate infinite;
      }
    }

    .bpmOrBtn {
      width: 3rem;
      @include flex;

      label {
        display: inline-block;
        width: 100%;
        font-style: normal;
        padding: 0.5rem;
      }

      .btn {
        background: none;
        border: 1px solid var(--fg);
        border-radius: 0.5rem;
        outline: none;

        .icon {
          fill: var(--green);
        }
      }
    }
  }
}

#stop,
#start,
#pause {
  .icon {
    fill: var(--fg);
  }
}

#record,
#restart {
  background: var(--bgHTML);
  border: 1px solid var(--red);
  .icon {
    min-height: 3rem;
    fill: var(--red);
    &.restart {
      min-height: 1.5rem;
    }
  }
}
