@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;
@use 'styles/colors' as *;

.sampleBtns {
  @extend %full;
  padding: 0.25rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.2rem;

  #sampleBtnsBorder {
    @extend %noEventsLayerAbs;
    border: 1px solid var(--fg);
    top: 0;
    border-color: transparent;
    &.highlight {
      border-color: initial;
    }
  }

  .sampleBtn {
    cursor: pointer;
    position: relative;
    @include flex();
    border: 1px solid var(--primary);
    background: var(--primary-darker);

    @media (hover: hover) {
      &:hover {
        border: 1px solid var(--fg);
      }
    }

    .icon {
      position: relative;
      z-index: 2;
      height: 5vw;
      max-height: 2rem;
      width: 5vw;
      max-width: 2rem;
    }

    .border,
    .bgFlash,
    .bgSelected {
      @extend %noEventsLayerAbs;
      opacity: 0;
      transition: 400ms;
      border-radius: 0;
    }
    .bgFlash {
      background: var(--primary);
    }
    .bgSelected {
      transition: none;
    }
  }
  .sampleBtn.flash,
  .sampleBtn.pulse {
    .border,
    .bgFlash {
      transition: none;
      opacity: 1;
    }
  }
  .sampleBtn.selected {
    .bgSelected {
      transition: none;
      opacity: 1;
    }
    .icon {
      fill: var(--primary-dark) !important;
    }
  }
}
