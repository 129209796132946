@mixin responsiveFont($responsive, $min, $max: false, $fallback: false) {
  $responsiveUnitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $minBreakpoint: $min / $responsiveUnitless * 100;

  @media (max-#{$dimension}: #{$minBreakpoint}) {
    font-size: $min;
  }

  @if $max {
    $maxBreakpoint: $max / $responsiveUnitless * 100;

    @media (min-#{$dimension}: #{$maxBreakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
