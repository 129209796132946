// prettierIgnored

.bg0 { background: var(--color0) !important; }
.border0 { border: 1px solid var(--color0) !important; }
.fill0 { fill: var(--color0) !important; }

.bg1 { background: var(--color1) !important; }
.border1 { border: 1px solid var(--color1) !important; }
.fill1 { fill: var(--color1) !important; }

.bg2 { background: var(--color2) !important; }
.border2 { border: 1px solid var(--color2) !important; }
.fill2 { fill: var(--color2) !important; }

.bg3 { background: var(--color3) !important; }
.border3 { border: 1px solid var(--color3) !important; }
.fill3 { fill: var(--color3) !important; }

.bg4 { background: var(--color4) !important; }
.border4 { border: 1px solid var(--color4) !important; }
.fill4 { fill: var(--color4) !important; }

.bg5 { background: var(--color5) !important; }
.border5 { border: 1px solid var(--color5) !important; }
.fill5 { fill: var(--color5) !important; }

.bg6 { background: var(--color6) !important; }
.border6 { border: 1px solid var(--color6) !important; }
.fill6 { fill: var(--color6) !important; }

.bg7 { background: var(--color7) !important; }
.border7 { border: 1px solid var(--color7) !important; }
.fill7 { fill: var(--color7) !important; }

.bg8 { background: var(--color8) !important; }
.border8 { border: 1px solid var(--color8) !important; }
.fill8 { fill: var(--color8) !important; }
