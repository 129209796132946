@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;
@use 'styles/colors' as *;

#sequencer {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  overflow: hidden;
}

#fullScreenPortal {
  @extend %noEventsLayerAbs;
  top: 0;
}

.mainContainer {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0.25rem;
  @include flex(column);

  @media (orientation: landscape) {
    flex-direction: row;
  }

  touch-action: none;
}

#main {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 70%;
  @include flex;
}

#overGridPortal {
  @extend %noEventsLayerAbs;
}

#samplePanel {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 30%;
  overflow: hidden;
  @include flex(column, space-evenly);

  .border {
    @extend %noEventsLayerAbs;
    border: 1px solid var(--fg);
    top: 0;
    opacity: 0;
  }

  .spTop {
    @extend %full;
  }
}

#menuBar {
  border-top: 1px solid var(--primary-dark);
}

#popupMenuPortal {
  @extend %noEventsLayerAbs;
  max-width: 1440px;
  top: 0;
  z-index: 1000;
  overflow: hidden;
}

@media (orientation: landscape) {
  .mainContainer {
    @include flex(row, space-evenly);
  }

  #main {
    max-width: 48%;
    max-height: 800px;
  }

  #samplePanel {
    max-width: 48%;
    max-height: 800px;
  }
}
