@use 'styles/placeholders' as *;
@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/responsiveFont' as *;

.grid {
  @extend %full;
  @include grid(8, 8, 3px);
  padding: 0.25rem;

  .cellWrapper {
    @extend %full;
    @media (hover: hover) {
      &:hover {
        border: 1px solid var(--fg);
      }
    }
  }

  .cell {
    @extend %full;
    cursor: pointer;
    opacity: 1;
    background: var(--grey-dim);
    transform: scale(1) rotate(0);
    transition: 200ms;
    transition-property: transform;

    &.pulse {
      transition: none;
      transform: scale(0.8) rotate(2deg);
    }

    .bg,
    .border,
    .pitch,
    .slice {
      @extend %noEventsLayerAbs;
    }

    .bg {
      background: var(--primary);
      opacity: 0;
      transform-origin: left;
    }
    &.flash,
    &.countIn {
      .bg {
        transition: none;
        @extend %turnOn;
      }
    }
    &[data-cursor='true'] {
      .bg {
        animation: flashing 200ms alternate infinite;
      }
    }

    .border {
      border: 1px solid var(--fg);
      opacity: 0;
      background: transparent;
    }
    &.flashing {
      .border {
        animation: flashing 500ms alternate infinite;
      }
      &.pause {
        .border {
          animation: none;
        }
      }
    }

    .pitch {
      z-index: 5;
      @include responsiveFont(5vw, 14px, 36px);
      padding: 0.15rem;
      color: var(--primary-dark);
    }
  }

  .slice {
    @include flex(row, center, center);
    z-index: 2;
    padding: 0.25rem;
    transform: scale(0);
    transition: 250ms ease-out;

    &.slice-2,
    &.slice-3 {
      opacity: 1;
    }

    &.slice-2 {
      transform: scale(0.5);
    }

    &.slice-3 {
      transform: scale(1);
    }

    svg {
      width: 100%;
      height: 100%;
      stroke-width: 2px;
      fill: transparent;
      stroke: var(--primary-dark);
      transform: rotate(0);
      transition: 300ms ease-out;
    }
  }
  .cell.pulse .slice svg {
    transition: none;
    transform: rotate(360deg);
  }

  .sample-cells {
    @extend %noEventsLayerAbs;
    @include grid(3, 3, 1px, auto, auto);
    align-items: initial;
    padding: 0.25rem;
    opacity: 0.5;

    .sample-cell {
      color: transparent;
      transform: scale(0.5);
      border: 1px solid;
      border-radius: 50%;
      transition: 200ms;
    }
  }
  .cell.flash .sample-cell,
  .cell.pulse .sample-cell {
    transition: none;
    transform: scale(2);
  }
}
