@use 'styles/colors' as *;

%full {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

%noEventsLayer {
  @extend %full;
  pointer-events: none;
}

%fullAbs {
  @extend %full;
  position: absolute;
}

%noEventsLayerAbs {
  @extend %fullAbs;
  pointer-events: none;
}

%turnOn {
  transition: none;
  opacity: 1;
}

%turnOff {
  transition: none;
  opacity: 0;
}

%textCenterPadding {
  padding: 0.5rem;
  text-align: center;
}

%btnFont {
  font-family: inherit;
  font-style: inherit;
  font-size: 0.8rem;
  text-transform: capitalize;
  letter-spacing: 3px;
}

%input {
  padding: 0.5rem;
  outline: none;
  border: 1px solid var(--fg);
  border-radius: 0.3rem;
  color: var(--fg);
  background: var(--bgHTML);
}

%inputBtn {
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
  padding: 0.75rem;
  outline: none;
  border: none;
  opacity: 0.5;
  @extend %btnFont;

  &:disabled {
    cursor: initial;
  }
}

%btn {
  cursor: pointer;
  width: auto;
  height: auto;
  min-height: 2rem;
  @extend %input;
  @extend %btnFont;

  &:disabled {
    cursor: initial;
  }
}

%closeBtnSmall {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
  background: none;
  fill: var(--fg);
  color: var(--fg);
  border: none;

  .chevron-left {
    width: 100%;
  }
}

%dummySmall {
  width: 4rem;
  height: 100%;
}

%bottomBtn {
  pointer-events: initial;
  cursor: pointer;
  position: fixed;
  z-index: 101;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  background: var(--bgHTML3q);

  &:disabled {
    cursor: initial;
  }
}

%btnFull {
  cursor: pointer;
  min-height: 3rem;
  width: 100%;
  margin: 0.25rem 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  transform: scale(1);

  &:disabled {
    cursor: initial;
  }
}
