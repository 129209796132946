@use 'styles/mixins' as *;
@use 'styles/placeholders' as *;
@use 'styles/colors' as *;

.knob {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  @include flex;

  svg {
    flex: 0 1 auto;
    height: 100%;
    width: 100%;
  }

  .arrowUpDown,
  label {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.arc {
  stroke-dasharray: 126px;
  color: var(--primary);
}

// bottom stroke 'container'
.arc-hide {
  /* display: none; */
}

.icon.arrowUpDown {
  height: 1rem;
  width: 1rem;
}
