@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;
@use 'styles/colors' as *;

.saveSequence {
  @extend %full;
  max-width: 600px;
  padding: 1rem 0.5rem;

  .share,
  .save,
  .download {
    border: 1px solid var(--primary-half);
    margin-bottom: 1rem;
    padding: 2rem;

    .header {
      padding: 0;
      padding-bottom: 0.5rem;
      width: 100%;
      text-align: left;
      font-size: 1.25rem;
      border-bottom: 1px solid var(--primary-half);
    }
  }

  .share {
    p {
      margin-top: 1rem;
      color: var(--fgHalf);
    }

    .shareLink {
      padding: 0.5rem;
      display: flex;

      #sequence-link {
        width: 100%;
        @extend %input;
      }

      .btn {
        @extend %btn;
        margin-left: 0.25rem;
        max-width: 5rem;
      }
    }

    .socialLinks {
      margin-top: 0.25rem;
      width: 100%;
      height: 2.5rem;
      @include flex(row, space-evenly);

      a {
        @extend %full;
      }

      .btn {
        width: 100%;
        height: 100%;
        padding: 1rem;
        background: none;
        border: none;
        @include flex;
        justify-content: flex-start;

        span {
          color: var(--fg);
          margin-left: 0.5rem;
        }

        .icon {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }

  .save {
    .header {
      @include flex(row, space-between);

      .saveLocation {
        font-size: 0.8rem;
        font-weight: lighter;
        color: var(--fgHalf);
      }
    }

    .saveInputWrapper {
      width: 100%;
      margin: 1rem 0;
      @include flex(column, space-evenly);

      .nameInput {
        @extend %input;
        @extend %full;
        margin: 0.5rem 0;
        overflow: hidden;
        font-size: 1.25rem;
      }

      .btn {
        @extend %btn;
        width: 100%;
      }
    }

    .fileLimit {
      p {
        padding-top: 0.5rem;
      }

      span {
        margin-left: 0.5rem;
        font-size: 1.25rem;
        color: var(--green);

        &.error {
          color: var(--red);
        }
      }
    }
  }

  .download {
    .downloadMain {
      @extend %full;

      .notSupported {
        color: var(--fgHalf);
        margin: 1rem 0;
      }

      .sub {
        padding: 1rem;
      }

      .downloadInputs {
        width: 100%;
      }

      .countAndDuration {
        margin: 1rem 0 0.5rem 0;
        width: 100%;
        @include flex(row, space-evenly);

        .btn {
          @extend %btn;
          margin-left: 1rem;
        }
      }

      .downloadBtn {
        margin: 0.5rem 0;
        @extend %btn;
        width: 100%;
      }
    }
  }
}
