@use 'styles/colors' as *;

@mixin grid(
  $row,
  $col,
  $gap,
  $rowSize: minmax(0, 1fr),
  $colSize: minmax(0, 1fr)
) {
  display: grid;
  align-items: center;
  grid-template-rows: repeat($row, $rowSize);
  grid-template-columns: repeat($col, $colSize);
  gap: $gap;
}

@mixin flex($dir: row, $j: center, $a: center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $j;
  align-items: $a;
}
