@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;
@use 'styles/colors' as *;
@use 'styles/responsiveFont' as *;

#samplePanel {
  .detail {
    @extend %fullAbs;
    top: 0;
    left: 0;
    z-index: 5;
    background: var(--bgHTML3q);
    border: 1px solid var(--primary-half);
    border-radius: 0;
    @include flex(row, space-evenly);
    overflow: hidden;

    &.dark {
      background: var(--bgHTML90);
    }

    @media (orientation: landscape) {
      flex-direction: column-reverse;
      justify-content: center;
      .dummy {
        display: none;
      }
      .middle {
        max-height: 50%;
      }
    }

    .icon {
      height: auto;
      max-width: 5rem;
      &.chevron-down {
        max-height: 2rem;
        max-width: 2rem;
      }
    }

    &.col {
      flex-direction: column;
    }

    .btn {
      @extend %btn;
    }

    .close.btn {
      @extend %closeBtnSmall;
    }
    .dummy {
      @extend %dummySmall;
    }

    .middle {
      @extend %full;
      max-width: 50%;
      @include flex(column, space-evenly);
      text-align: center;
      white-space: initial;

      @media (orientation: landscape) {
        max-width: 100%;
      }
    }
  }

  .icon.slicing {
    animation: slicing 2000ms infinite linear;
  }

  // PitchVelocityLength
  .modWrapper {
    @extend %full;
    @include flex(column, space-evenly);
    @media (orientation: landscape) {
      max-height: 250px;
    }
    @include responsiveFont(4vw, 14px, 20px);

    .modSliderWrapper {
      @extend %full;
      max-width: 50%;
      min-height: 25%;
      @include flex;
    }

    #modSlider {
      width: 100%;
      height: 10%;
      background: var(--primary);
      border-radius: 0.25rem;
      transform-origin: left;
    }

    .modSliderThumbWrapper {
      @extend %noEventsLayerAbs;
      width: 100%;
      height: 50%;
      left: -1rem;
      @include flex;
      justify-content: flex-start;

      .modSliderThumb {
        background: var(--primary);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }
    }

    .selectLabel,
    .applyInfo {
      height: 100%;
      @include flex;
    }

    .customSelectWrapper {
      width: 50%;
      height: 100%;
      @include flex;
    }

    .modValueWrapper {
      @extend %full;
      @include flex;
    }

    .modLabel {
      display: inline-block;
      text-align: right;
    }

    .modValue {
      min-width: 20%;
      display: inline-block;
      text-align: center;
    }

    input[type='range'] {
      height: 100%;
      width: 50%;
      cursor: pointer;
    }

    .applyInfo {
      opacity: 0;
      transition: 2s;
      &.show {
        transition: none;
        opacity: 1;
      }
    }
  }

  .modBtns {
    @extend %full;
    @include flex(row, space-evenly);

    @media (orientation: landscape) {
      padding: 2rem 0;
    }

    .btn {
      @extend %btn;

      &.bgGreen {
        color: var(--bg);
        background: var(--green);
      }
    }
  }
}
