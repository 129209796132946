@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;

.loginPage {
  position: absolute;
  z-index: 201;
  pointer-events: initial;
  min-height: 100%;
  width: 100%;
  background: var(--bgHTML90);
  @include flex(column);
}

h1 {
  max-width: 600px;
  padding: 1rem;
  font-size: 1rem;
}

.loginMethods {
  padding: 1rem;
  width: 100%;
  max-width: 600px;
  background: var(--bg);
  border-radius: 0.5rem;
}

.methodBtn {
  margin: 1rem 0;
  padding: 1rem;
  background: var(--bg90);
  max-height: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.methodBtn img {
  height: 2rem;
  max-height: 50px;
}

.methodBtn p {
  color: var(--fg);
}
.loginPage .bottomBtn {
  transform: translateX(-50%);
}
