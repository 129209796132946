@use 'styles/placeholders' as *;
@use 'styles/mixins' as *;
@use 'styles/colors' as *;

.statusBar {
  pointer-events: none;
  position: fixed;
  height: 2.5rem;
  width: 100%;
  z-index: 201;
  @include flex;

  .status {
    display: inline-block;
    padding: 0.5rem;
    font-size: 1rem;
    font-family: Orbitron, Roboto, sans-serif;
    color: var(--red);
    letter-spacing: 2px;
    text-align: center;
    background: var(--bgHTML90);
    opacity: 0;
    transition: 1500ms;

    &.fadeOut {
      @extend %turnOn;
    }

    &.fadeOut2 {
      opacity: 1;
    }
  }
}
