@use 'styles/placeholders' as *;
@use 'styles/colors' as *;

.customSelectWrapper {
  position: relative;
  width: 50%;
  padding: 0.5rem;
  color: var(--fg);
  outline: none;
  border: none;
  border-radius: 0.3rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .customSelect {
    cursor: pointer;
    -webkit-appearance: none;
    width: 100%;
    padding: 0.5rem;
    font-size: 1.25rem;
    border: 1px solid var(--primary);
    border-radius: 0.5rem;
    background: var(--bgHTML);
    color: var(--fg);
    overflow: hidden;
  }

  .chevron-down {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    height: 2rem;
    fill: var(--fg);
    width: auto;
  }
}
