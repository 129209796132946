html {
  --grey-dim: rgb(150, 140, 170, 0.15);
  --green: rgba(47, 222, 126, 1);
  --green-dark: rgb(40, 170, 99);
  --red: rgb(232, 12, 48);

  --primary: rgba(113, 27, 228, 1);
  --primary-3q: rgba(113, 27, 228, 0.75);
  --primary-half: rgba(113, 27, 228, 0.5);
  --primary-third: rgba(113, 27, 228, 0.33);
  --primary-quarter: rgba(113, 27, 228, 0.25);

  --primary-dark: rgb(60, 10, 99);
  --primary-dark-half: rgba(60, 10, 99, 0.5);

  --primary-darker: rgb(34, 8, 68);

  --primary-darkest: rgb(19, 4, 37);
  --primary-darkest-half: rgba(19, 4, 37, 0.9);
  --fg: rgb(255, 255, 255);
  --fg90: rgba(255, 255, 255, 0.9);
  --fg3q: rgba(255, 255, 255, 0.75);
  --fgHalf: rgba(255, 255, 255, 0.5);
  --fgQuarter: rgba(255, 255, 255, 0.25);

  --bgHTML: black;
  --bgHTML90: rgba(0, 0, 0, 0.9);
  --bgHTML3q: rgba(0, 0, 0, 0.75);
  --bgHTMLHalf: rgba(0, 0, 0, 0.5);

  --bg: rgb(21, 19, 24);
  --bg90: rgba(21, 19, 24, 0.9);
  --bg3q: rgba(21, 19, 24, 0.75);
  --bgHalf: rgba(21, 19, 24, 0.5);

  --color0: rgb(231, 15, 111);
  --color1: rgb(230, 18, 173);
  --color2: rgb(227, 21, 230);
  --color3: rgb(169, 24, 229);
  --color4: rgb(58, 30, 227);
  --color5: rgb(33, 60, 226);
  --color6: rgb(36, 115, 225);
  --color7: rgb(39, 169, 225);
  --color8: rgb(41, 221, 224);
}
